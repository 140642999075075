import React from 'react';
import wp from '../images/w.png'

const Contact = () => {
    return ( 
<footer>
		<div className="container center-text">
			<ul className="social-icons">
				
			{/*	<li><a href="https://wa.me/917076301527" target="_blank" rel="noopener noreferrer"><img alt="WP" src={wp} /></a></li> */}
			</ul>
		</div>
	</footer>
        );
    }
    
    export default Contact;